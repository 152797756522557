<template>
  <div>
    <md-field>
      <md-input
        autocomplete="false"
        v-model="stockIds"
        id="stockIds" name="stockIds"
        @input="onInput"
        placeholder="Digite para buscar..."
      />
    </md-field>

    <div v-if="selectedResults.length > 0">
      <span 
        v-for="(result, index) in selectedResults" 
        :key="index">
        {{ result[displayField] }}<strong @click="removeSelection(index)"><md-icon class="remover-icon">close</md-icon></strong>
      </span>
    </div>

    <ul v-if="results.length > 0">
      <li 
        v-for="(result, index) in results" 
        :key="index" 
        @click="onSelect(result)">
        {{ result[displayField] }}
      </li>
    </ul>
  </div>
</template>



<script>
export default {
  props: {
    apiUrl: {
      type: String,
      required: true,
    },
    paramName: {
      type: String,
      required: false,
    },
    displayField: {
      type: String,
      required: true, // Campo obrigatório para exibir o dado correto
    },
    authToken: {
      type: String,
      required: false,
    },
    multiple: {
      type: Boolean,
      default: true, // Controla se o componente é múltiplo ou único
    },
  },
  data() {
    return {
      stockIds: '',
      results: [],
      selectedResults: [],
    };
  },
  methods: {
    onInput() {
      if (this.stockIds.length > 0) {
        const headers = new Headers({
          'accept': 'application/json',
        });

        if (this.authToken) {
          headers.append('Authorization', `Bearer ${this.authToken}`);
        }
        
        var url = process.env.VUE_APP_API_BASE_URL
        fetch(`${url}${this.apiUrl}?${this.paramName}=${this.stockIds}`, { headers })
          .then((response) => response.json())
          .then((data) => {
            this.results = data; // Ajuste conforme a estrutura da resposta da sua API
          })
          .catch((error) => console.error('Erro:', error));
      } else {
        this.results = [];
      }
    },
    onSelect(result) {
      if (this.multiple) {
        this.selectedResults.push(result);
        this.$emit('select', result);
      } else {
        this.selectedResults = [result];
        this.$emit('select', result);
      }
      this.stockIds = '';
      this.results = [];
    },
    removeSelection(index) {
      var valor = this.selectedResults.splice(index, 1);
      // Atualizar os valores emitidos após a remoção
      this.$emit('update:select', this.selectedResults.map(r => index));
      console.log(valor[0].stockId);
      this.$emit('remove', valor[0].stockId);
    },        
  },
};
</script>

<style scoped>
/* Adicione estilos conforme necessário */
ul {
  list-style-type: none;
  padding: 0;
}
li {
  cursor: pointer;
}
.remover-icon {
  cursor: pointer; /* Muda o cursor para mãozinha */
  font-size: 24px; /* Aumenta o tamanho do ícone */
  color: red; /* Cor padrão */
  transition: color 0.3s; /* Suave transição de cor ao passar o mouse */
}

.remover-icon:hover {
  color: darkred; /* Cor diferente no hover */
}
</style>
import Vue from "vue";
import router from "@/router";
import { VueAuthenticate } from "vue-authenticate";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

const vueAuth = new VueAuthenticate(Vue.prototype.$http, {
  baseUrl: process.env.VUE_APP_API_BASE_URL,
  tokenName: "access_token",
  loginUrl: "/authentication/login",
  registerUrl: "/register/create"
});

const vuePartnerAuth = new VueAuthenticate(Vue.prototype.$http, {
  baseUrl: process.env.VUE_APP_API_BASE_URL,
  tokenName: "access_token",
  loginUrl: "/authentication/login-partner",
  registerUrl: "/register/create"
});

const url = process.env.VUE_APP_API_BASE_URL;

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401) {
    localStorage.removeItem("vue-authenticate.vueauth_access_token");
    router.push({path: "/login"});
  }
  return Promise.reject(error);
});

export default {
  state: {
    isAuthenticated: localStorage.getItem("vue-authenticate.vueauth_access_token") !== null,
    isAuthenticatedPartner: localStorage.getItem("vue-authenticate-partner.vueauth_access_token") !== null,
    confirmationHash: null,
    token: localStorage.getItem("vue-authenticate.vueauth_access_token"),
    tokenPartner: localStorage.getItem("vue-authenticate-partner.vueauth_access_token"),
    redirectTo: null,
    selectedPlan: null,
  },

  getters: {
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    isAuthenticatedPartner(state) {
      return state.isAuthenticatedPartner;
    },
    confirmationHash(state) {
      return state.confirmationHash;
    },
    token(state){
        return state.token;
    },
    tokenPartner(state){
      return state.tokenPartner;
  }
  },

  mutations: {
    isAuthenticated(state, payload) {
        state.isAuthenticated = payload.isAuthenticated;
    },
    isAuthenticatedPartner(state, payload) {
        state.isAuthenticatedPartner = payload.isAuthenticatedPartner;
    },
    confirmationHash(state, payload) {
        state.confirmationHash = payload.confirmationHash;
    },
    token(state, payload){
        state.token = payload.token;
    },
    tokenPartner(state, payload){
      state.tokenPartner = payload.tokenPartner;
  }
  },

  actions: {
    login(context, payload) {
      return vueAuth.login(payload.user, payload.requestOptions).then(response => {
        context.commit("isAuthenticated", {
            isAuthenticated: vueAuth.isAuthenticated()
        });
        context.commit("token", {
            token: response.data.access_token
        });
      });
    },

    loginAsClient(context, payload) {
      return axios.post(`${url}/authentication/login-as-client`, payload.user, payload.requestOptions)
        .then(response => {
          context.commit("isAuthenticated", {
              isAuthenticated: true
          });
          context.commit("token", {
              token: response.data.access_token
          });
          localStorage.removeItem("vue-authenticate.vueauth_access_token")
          localStorage.setItem("vue-authenticate.vueauth_access_token", response.data.access_token)
      });
    },

    loginPartner(context, payload) {
      return vuePartnerAuth.login(payload.user, payload.requestOptions).then(response => {
        context.commit("isAuthenticatedPartner", {
          isAuthenticatedPartner: vueAuth.isAuthenticated()
        });
        context.commit("isAuthenticated", {
            isAuthenticated: vueAuth.isAuthenticated()
        });
        context.commit("tokenPartner", {
          tokenPartner: response.data.access_token
        });
        localStorage.setItem("vue-authenticate-partner.vueauth_access_token", response.data.access_token)
      });
    },

    register(context, payload) {
      return vueAuth.register(payload.user, payload.requestOptions).then(response => {
        context.commit("isAuthenticated", {
          isAuthenticated: vueAuth.isAuthenticated()
        });
      });
    },
    async getReferralName(context, payload){
      return axios.get(`${url}/person/referral/`+payload.referral.code, payload.requestOptions)
        .then(response => {
            return response.data.name;
        });
    },
    logout(context, payload) {
        try{
            return vueAuth.logout().then(response => {
                context.commit("isAuthenticated", {
                    isAuthenticated: vueAuth.isAuthenticated()
                });
                context.commit("isAuthenticatedPartner", {
                  isAuthenticatedPartner: vueAuth.isAuthenticated()
                });
                localStorage.removeItem('vue-authenticate-partner.vueauth_access_token')
                router.push({name: "Login"});
            });
        }catch(e){
            router.push({name: "Login"});
        }
    },

    async send_code(context, payload) {
        return axios.post(`${url}/register/send-code`, payload.user, payload.requestOptions)
        .then(response => {
            context.commit("confirmationHash", {
                confirmationHash: response.data.result
            });
            router.push({name: "ConfirmEmail"});
        });
    },
    async createRegisterPartner(context, payload) {
      return axios.post(`${url}/register/create/partner`, payload.user, payload.requestOptions)
      .then(response => {
          return response.data
      });
    },
    async sendForgotCode(context, payload) {
      return axios.post(`${url}/authentication/send-forgot-code`, payload.user, payload.requestOptions)
      .then(response => {
          context.commit("confirmationHash", {
              confirmationHash: response.data.result
          });
          router.push({name: "ResetPassword"});
      });
    },
    async resetPassword(context, payload) {
      return axios.post(`${url}/authentication/reset-password`, payload.user, payload.requestOptions)
      .then(response => {
          router.push({name: "Login"});
      });
    },
  }
};
